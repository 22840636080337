.addStudentManinContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 10px;
    margin-top: 75px;
}

.addStudentContainer {
    display: flex;
    flex-direction: row;
    width: auto;
}

.addStudentHeaderTxtContainer {
    display: flex;
    flex: 0.5;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    padding-left: 20px;
    padding-top: 20px;
}

.addStudentHeaderBtnContainer {
    display: flex;
    flex-direction: row;
    flex: 0.5;
    align-items: center;
    justify-content: flex-end;
    gap: 15px;
    padding-top: 20px;
    padding-right: 20px;
}

.addTextfieldContainer {
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding: 20px;

    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000008;
    /* border: 1px solid #DFE3E8; */
    border-radius: 20px;
    opacity: 1;
}

.AddStudentTextFieldContainer {
    display: flex;
    flex-direction: row;
    gap: 25px;
}

.addNewStudentContainer {
    padding: 20px;
}

.addNewSchoolContainer {
    padding: 20px;
}

.addNewTeacherContainer {
    padding: 20px;
}

.cancelBtn {
    max-width: 81px;
    max-height: 35px;
    background: #FFFFFF 0% 0% no-repeat padding-box !important;
    border: 1px solid #7DA8FF !important;
    border-radius: 8px !important;
    opacity: 1 !important;
}

.saveDataBtn {
    background-color: #B3DAFF !important;
    max-width: 120px;
    max-height: 35px;
    border: 1px solid #DEDEDE !important;
    border-radius: 8px !important;
    opacity: 1 !important;
}

.AddFormContainer {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000008 !important;
    border: 1px solid #DFE3E8 !important;
    border-radius: 16px !important;
    opacity: 1 !important;
}

@media screen  and (min-width:320px) and (max-width: 520px) {
    .addTextfieldContainer {
        display: flex;
        flex-direction: column;
        gap:5px;
    }    
    .AddStudentTextFieldContainer {
        display: block;
    }

    .AddStudentTextFieldContainer>div {
        padding-bottom: 20px;
    }
    .addStudentManinContainer{
        gap:5px;
        padding-top: 47px;
    }
    .addNewSchoolContainer{
        padding:10px;
    }
    .addStudentHeaderBtnContainer{
        flex:0.6;
    }
    /* .css-1rer79v-MuiDataGrid-root .MuiDataGrid-row{
        max-height: 52px;
    min-height: 60px;
    } */
    .css-1rer79v-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell{
        min-width: 183.5px;
    max-width: 183.5px;
    /* min-height: 65px; */
    max-height: 66px;
    min-height: 62px !important;
    }
    .css-1rer79v-MuiDataGrid-root .MuiDataGrid-row  {
        max-height: 52px;
    min-height: 77px;
    }
     .mobileColumnLabelOne > p{
        font-size: 11px;
        font-weight: bold;
    } 
 

   
}