.authViewContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: #F7F7F7;
}

.authViewMain {
    display: flex;
    flex: 1;
    flex-direction: row;
}

.visibility {
    display: none;
}

.authSearchbtn {
    /* max-width: 81px; */
    min-width: 80px;
    background-color: #B3DAFF !important;
    border-radius: 8px !important;
    border: 1px solid #DEDEDE !important;

}

.blueBtnBg {
    background-color: #B3DAFF !important;
    border-radius: 8px !important;
}

.textFontProxima {
    font-family: 'Proxima Nova' !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    background: white !important;
}

.blueColorText {
    color: #1F66C4 !important;
}

.manageBtn {
    border: 1px solid #DEDEDE !important;
    border-radius: 8px;
    max-height: 35px;
    margin-left: 15px !important;
}

@media screen and (min-width: 320px) and (max-width: 520px) {
    .manageBtn {
        margin-left: unset !important;
    }
}