.myImageContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  gap: 48px;
  object-fit: contain;
  padding-top: 20px;
}

.myImageTextContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}

.myImageMainContainer {
  margin-top: 8%;
  padding-left: 15px;
  flex: 1;

}

.myIamgeBtnContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.myImageSearchbarContainer {
  display: flex;
  flex-direction: row;
  flex: 0.5;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  padding-right: 2%;
}

.myImageBtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  flex: 0.5;
  padding-right: 2%;
  gap: 10px;
}

.myImageSearchBtnContainer {
  display: flex;
  flex-direction: row;
  flex: 0.5;
}

.myImageSearchbarButtonContainer {
  display: flex;
  flex-direction: column;
  flex: 0.5;
}

.myImagesComponent {
  max-width: 190px;
  max-height: 232px;
}

.myImageBtn>h3 {
  font-size: 13px;
}

.favouriteImages {
  background-color: #B3DAFF;
}

.myImages {
  background-color: #B3DAFF;
}

.myImageMenuTabContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end
}

.myImageSearchAuthSearchFieldContainer {
  display: flex;
  gap: 20px;

}

.myImageTabMenuContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.myImageUploadImageContainer {
  display: flex;
  flex-direction: '';
  gap: 200px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
}

.myImageEnterTextFieldContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 1.5;
}

.myImagePreviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.myImagePreviewImageContainer {
  display: flex;
  background-color: gray;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 100px 90px;
  border-radius: 8px;
  max-width: 100px;
  min-height: 70px;
}

.myImageChooseFile {
  display: inline-block;
  border: 1px solid #DEDEDE;
  padding: 10px 12px;
  border-radius: 8px;
  background-color: #B3DAFF;
}

.rejectBtn:hover {
  background-color: red;
}


@media screen and (min-width: 320px) and (max-width: 520px) {
  .myImageSearchbarContainer {
    display: flex;
    align-items: unset;
    padding-right: 8px;
    padding-top: 15px;
    flex-direction: column;
  }

  .myImageBtn>h3 {
    font-size: 19px;
    width: 210px;
  }

  .myImageSearchbarButtonContainer {
    display: flex;
    flex-direction: column;
    flex: 0.5;
    gap: 10px;
    padding-top: 15px;
  }

  .myImageContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1 1;
    gap: 11px;
    padding-top: 10px;
    align-items: center;
    justify-content: center;
  }

  .myImageMainContainer {
    padding-top: 100px;
  }

  .myImageTextContainer>img {
    width: 30px;
    /* height:30px; */
  }

  .myImageTextContainer>.imageHeading {
    font-size: 23px;
  }

  .myImageTextContainer {
    gap: 10px;
    display: flex;
    flex-direction: row;
    flex: 1;
    /* padding-left: 25px; */
  }

  .myImagesComponent {
    max-width: 188px;
    max-height: 250px;
    width: 246px;
    height: 254px;
    gap: 20px;
  }

  .myImageBtn {
    justify-content: unset;
  }

  .myImages {
    width: 108px;
    background-color: #B3DAFF;
  }

  .favouriteImages {
    width: 144px;
    background-color: #B3DAFF;
  }

  .myImageUploadImageContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }


}

@media screen and (min-width: 370px) and (max-width: 450px) {
  .myImagesComponent {
    max-width: 140px;
    max-height: 250px;

  }
}