/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.signUpScrollContainer {
    gap: 10px;
}

.react-horizontal-scrolling-menu--item  {
    display: flex;
}

.roleTextActive {
    user-select: none;
    text-align: center;
    background: white;
    padding: 4px 16px;
    border-radius: 19px;
    margin-bottom: 0;
}

.roleText {
    user-select: none;
    text-align: center;
    padding: 4px 16px;
    margin-bottom: 0;
}