@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');


 body {
  margin: 0;
  font-family: 'Nunito Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-height: 100vh;
} 

 code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} 

html body {
  min-height: 100vh;
}

#root {
  display: flex;
  min-height: 100vh;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #3a3838 #eeeeee;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  
}

*::-webkit-scrollbar-track {
  /* background: #eeeeee; */
}

*::-webkit-scrollbar-thumb {
  background-color: #adadad;
  border-radius: 0px;
  border: 0px solid #eeeeee;
}

 div:focus,a:focus, button:focus {
  outline: none;
}  

@media screen and (max-width: 540px) {

  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #3a3838 #eeeeee;
  
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 10px;
  }

  *::-webkit-scrollbar-track {
    background: #525252ad ;
   
  }

  *::-webkit-scrollbar-thumb {
    background-color: #3a3838;
    border-radius: 10px;
    border: unset;
  
  }
  p{
    user-select: none;
  }
}