.mobileViewMainContainer{
    background: transparent linear-gradient(180deg, #335089 0%, #5888EA 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
    display:flex;
    flex-direction: row;
    border-radius: 0px !important;
    align-items: center;
}
.stackContainer > img{
    width:40px;
    height:25px;
}
.stackContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    flex: 0.3;
}
.mobileViewBreadcrumb {
    padding-left: 24px;
    color: white !important;
}
.mobileViewProfileLogo{
    display: flex;
    flex-direction: column;
    /* padding-top: '17px'; */
    flex: 1;
    align-items: center;
    justify-content: flex-end;
}
.mobileBreadCrumb{
    display: flex;
    flex-direction: column;
    flex: 1;
}
.mobileViewHamburgerIcon {
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 14px;
}
.mobileHeader{
    position: fixed;
    width: 100%;
    z-index: 15;
}