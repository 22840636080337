.profileStackContainer {
    display: none;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
    flex: 0.3;
    height: 57px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000008;
    border: 1px solid #DFE3E8;
    border-radius: 16px;
    opacity: 1;


}

.profileCardContainer {
    /* display:flex; */
    /* flex: 0.5; */
    padding-left: 20px;
    padding-right: 66%;
}

.ProfileWrapper {
    display: flex;
    flex: 0.3;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 20px;
    gap: 15px;
    padding: 20px;
}

.profileLinkContainer {
    display: flex;
    flex: 0.7;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    padding: 15px;
}

.profileTextfieldContainer {
    display: flex;
    flex-direction: column;
    /* gap:25px; */
}

.profileTextFieldWrapperContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 15px;
    /* margin-top: 10px;
    margin-bottom: 10px; */
}

.ProfileAddressContainer {
    padding: 15px;
}

.profileManinContainer {
    display: flex;
    flex: 1;
    /* gap: 20px; */
    margin: 94px 0px 24px;
}

.profileWrapperContainer {
    display: flex;
    flex-direction: row;
    width: auto;
}

.profileHeaderTxtContainer {
    display: flex;
    flex: 0.5;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
}

.profilechangePasswordLinkContainer {
    display: flex;
    flex: 1;
    flex-direction: row;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000008;
    border: 1px solid #DFE3E8;
    border-radius: 16px;
    opacity: 1;
}

.myProfileContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.schoolCode {
    display: flex;
    /* padding: 0px 20px; */
}

@media screen and (min-width:320px) and (max-width: 520px){
    .profileTextFieldWrapperContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 15px;
        /* margin-top: -14px;
        margin-bottom: 10px; */
    }
    .profileLinkContainer {
        display: flex;
        flex-direction: column;
        flex:1;
        align-items: unset;
        gap: 20px;
    }
    .profilePwd {
        justify-content: center;
    }
    .myProfileContainer{
        padding-top: 10px;
    }
    .profileHeaderTxtContainer>p{
        margin-top: 25px;
    }
    .profileHeaderTxtContainer>img{
        margin-top: 25px;
    }

}