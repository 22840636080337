.paymentContainer {
    display: flex;
    flex: 1;
    gap: 20px;
   
}

.paymentWrapperLeft {
    display: flex;
    flex-direction: column;    
    flex: 0.6;
    gap: 40px;
}

.paymentWrapperRight {
    flex: 0.4;
    display: flex;
}

.subscriptionContainer {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.paymentCard {
    /* flex: 1; */
    display: flex;
    /* min-height: 200px; */
    border-radius: 16px;
    border: 1px solid #DFE3E8;
    background-color: white;
    box-shadow: 0px 3px 6px #00000008;
    padding: 20px;
}

.flexDirectionColumn {
    flex-direction: column;
    gap: 20px;
}

.paymentFieldsContainer {
    display: flex;
    gap: 20px;
}

.paymentFlex1 {
    flex: 1;
}

.paymentProceedBtn {
    margin-top: 30px !important;
    background-color: #B3DAFF  !important;
    color: black !important;
}

.paymentProceedBtn:hover {
    border: 1px solid #B3DAFF !important;
    background-color: white !important;
    /* color:  #B3DAFF  !important; */
}
.paymentTypographyContainer{
    display:flex;
     justify-content:space-between;
      border-bottom: 1px solid #DFE3E8;
       padding-bottom: 30;
}
.paymentTotalContainer{
    display: flex;
     justify-content:space-between;

}
.paymentSubscriptionTxtContainer{
    display: flex;
    flex-direction: row;
    flex: 1;
    column-gap: 17px;
}
.paymentSubscriptionTxtContainer>button{
    margin-top: 20px;
}
.paymentSubscriptionTxtContainer > img{
    margin-top: 25px;
}
@media screen and (min-width:320px) and (max-width: 520px) {
    .paymentContainer{
    display: flex;
    flex: 1 1;
    gap: 20px;
    flex-direction: column-reverse;
    }
    .paymentFieldsContainer{
        display: flex;
    flex-direction: column;
    gap: 20px;
    }
    .subscriptionContainer{
        padding-top: 20px;
    }
.paymentSubscriptionTxtContainer{
    display: flex;
    flex-direction: row;
    flex: 1;
    column-gap: 17px;
   
}
.paymentSubscriptionTxtContainer > button{
    margin-top: 12px;
}
.paymentSubscriptionTxtContainer > img{
    width: 20px;
    height:20px;
}
.paymentSubscriptionTopHeading{
    font-size: 22px;
}

.paymentSubscriptionTxtContainer > img{
    margin-top: 19px;
}

}
@media screen and (min-width:370px) and (max-width: 418px) {
    .paymentSubscriptionTopHeading{
        font-size: 16px;
        margin-top: 16px;
    }
}
@media screen and (min-width:320px) and (max-width: 370px) {
    .paymentSubscriptionTopHeading{
        font-size: 12px;
        margin-top: 20px;
    }
}
@media screen and (min-width:419px) and (max-width: 520px) {
    .paymentSubscriptionTopHeading{
        font-size: 20px;
        margin-top: 20px;
    }
}