/* chrome and chromium based */
.react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
    display: none;
}

.react-horizontal-scrolling-menu--scroll-container {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.react-horizontal-scrolling-menu--wrapper {
    flex: 1;
}

.subMenuScrollWrapper {
    width: calc(100vw - 160px);
}

.subjectScrollWrapper {
    width: calc(100vw - 515px);
}

.subMenuSeparator {
    min-width: 112px;
}

.subjectSeparator {
    min-width: 10px;
}

.react-horizontal-scrolling-menu--arrow-left, .react-horizontal-scrolling-menu--arrow-right {
    display: flex;
    /* width: 94px; */
    justify-content: center;
}