.adsMainContainer {
    display: flex;
    min-height: 300px;
    flex: 1;
    background-color: white;
}

.adsAiWrapper {
    flex: 0.5 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.adsImagesContainer {
    flex: 0.7;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}

.adsSectionDivider {
    width: 4px;
    background-color: #EBEBEB
}

.mySwiper {
    /* display: flex; */
    flex: 1;
}

.mySwiper > div:nth-child(1) {
    /* display: flex; */
    height: 400px;
}

.mySwiper > div > div > div > div, .mySwiper > div > div > div > div > div, .mySwiper > div > div > div > div > div >div {
    display: flex;
    flex: 1;
}

.slide1 {
    background-image: url('../../assests/ads/banner\ slide\ page-1.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* height: 400px; */
    /* width: 800px; */
}

.slide2 {
    background-image: url('../../assests/ads/banner\ slide\ page-2.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* height: 400px; */
    /* width: 800px; */
}

.slide3 {
    background-image: url('../../assests/ads/banner\ slide\ page-3.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* height: 400px; */
    /* width: 800px; */
}

.slide4 {
    background-image: url('../../assests/ads/banner\ slide\ page-4.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* height: 400px; */
    /* width: 800px; */
}

.slide5 {
    background-image: url('../../assests/ads/banner\ slide\ page-5.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* height: 400px; */
    /* width: 800px; */
}

.slide6 {
    background-image: url('../../assests/ads/banner\ slide\ page-6.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* height: 400px; */
    /* width: 800px; */
}

.slide7 {
    background-image: url('../../assests/ads/banner\ slide\ page-7.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* height: 400px; */
    /* width: 800px; */
}

.slide8 {
    background-image: url('../../assests/ads/banner\ slide\ page-8.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* height: 400px; */
    /* width: 800px; */
}

.slide9 {
    background-image: url('../../assests/ads/banner\ slide\ page-9.png');
    background-size: contain;
    background-repeat: no-repeat;
    /* height: 400px; */
    /* width: 800px; */
}

@media  screen and (width : 1368px) {
    .adsAiWrapper {
        flex: 0.5;
    }
}

@media screen and (width : 1920px) {
    .slide6 {
        /* background-image: url('../../assests/ads/Slide7.jpg'); */
    }
    
}

@media screen and (min-width: 370px) and (max-width: 540px) {
    .adsMainContainer {
        font-display: flex;
        flex-direction: column;
        gap: 10px;
    }    
    .mySwiper > div:nth-child(1) {
        /* display: flex; */
        height: 150px;
    }
    
    .mySwiper > div > div > div > div, .mySwiper > div > div > div > div > div, .mySwiper > div > div > div > div > div >div {
        display: flex;
        flex: 1;
    }
    .slide1, .slide2, .slide3, .slide4, .slide5, .slide6, .slide7, .slide8, .slide9 {
        background-size: cover;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1420px) {
    .mySwiper > div:nth-child(1) {
        /* display: flex; */
        height: 300px;
    }
}