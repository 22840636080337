.footerMainContainer{
    display:flex;
    flex-direction: row;
    flex:1;
    
  
   
}
.footertextContainer{
    display:flex;
    flex-direction:row;
    flex:0.6;
    align-items:center;
    justify-content:center;
    gap:40px;
    color:gray;
    padding:40px;
}
.indiaFooterFlag{
    display:'flex';
    flex-direction: row;
    flex:0.1;
    background-image: url("../../../../assests/indiaFlag.png");
    background-repeat: no-repeat;
    background-position: center;

}
.countryFlag{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 27px;
    border-radius: width;
   width: 30px;
     /* height: 30px; */
}