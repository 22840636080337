.typoContainer{
    display:flex;
    flex-direction:row;
    gap:10px;
    align-items:center;
    justify-content:flex-start;
}

.analyticsContainer {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 94px 24px 24px;
    gap: 20px;
}

.analyticsBigCards {
    min-height: 80px;
    min-width: 200px;
    display: flex;
    padding: 25px;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px !important;
    border-radius: 16px;
}

@media screen and (min-width:320px) and (max-width: 520px) {
    .analyticsContainer {
        margin-top: 130px;
        width: 87vw;
    }
}