.headContainer{
    flex:1;
    height:60px;
    border:2px  solid;
    display:flex;
    flex-direction:row;
}
.menuGutter{
    flex:0.1;
    background: transparent linear-gradient(180deg, #335089 0%, #5888EA 100%) 0% 0% no-repeat padding-box;
    opacity: 1;
}
.outLineMenu{
    width:60px;
    height:40px;
    color:white;
   padding-top:10px;
   

}
.smallItemContainer{
    flex:0.5;
    display: flex;
    flex-direction:row;
    justify-content:space-evenly;
    row-gap:'20px';
}
.Crumbs{
    flex:1;
    flex-direction: row;
    justify-content:space-evenly ;
    padding-top:17px
}
.profileLogo{
    flex-direction: row;
   justify-content: space-evenly;
   padding-top:'17px'
}
.flagGutter
{
    flex:0.1;
}
.logoSchool{
    width:auto;
}

.hamburgerBtn {
    background: transparent linear-gradient(180deg, #335089 0%, #5888EA 100%) 0% 0% no-repeat padding-box;
    max-width: 57px !important;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    width: 120px;
}

.sideNavContainer {
    flex: 1;
    max-width: 200px;
    z-index: 100;
}

.headerContainer {
    z-index: 200;
    min-width: 100%;
    position: fixed;
    top: 0;
}

.sideNavListItemsHeading {
    margin-left: 17px !important;
    font-size: 12px !important;
    font-family: "Proxima Nova" !important;
    text-transform: uppercase !important;
    font-weight: 300 !important;
    color: #707070 !important;
}

.linkTypoProxima {
     font-family: "Proxima Nova" !important;
    font-weight: 300 !important; 
    color:#293859;
    font-size:15px;
}

    /* a:active {
        color: blue;
      } */
      .isActive{
        color:red;
    }
      
    @media screen and (min-width: 320px) and (max-width: 520px) {
    .sideNavContainer {
        display: none;
    }
    .hamburgerBtn {
        background: white;
    }
    .authIcon {
        display: flex;
        flex: 1.9;
        padding-top: 12px;
        padding-left: 10px;
    }
}