.loginParentCard {
  display: flex;
  flex-direction: row-reverse;
  overflow-y: hidden;
  align-items: flex-start;
  gap: 30px;
  max-width: 900px;
  flex: 0.7;
  border-radius: 10px;
  /* background-color: lightblue; */
}
.loginForm {
  padding: 0px 100px 54px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.loginNav {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding-top: 18px;
  border-bottom: 1px solid #DDDDDD;
  position: relative;
}
.loginHeading {
  /* padding-top: 30px; */
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
}
.loginTextFieldContainer {
  padding-top: 15px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.loginRememberContainer {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.loginResendOtpContainer {
  width: 100%;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.loginRememberContainer > div {
  display: flex;
  gap: 10px;
}
.loginContBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 20px !important;
}
.loginContBtn:hover {
  background-color: #7BA6FB !important;
  box-shadow: unset !important;
  border-radius: 4px !important;
}
.loginTabBtn {
  border-radius: 8px 8px 0px 0px !important;
  box-shadow: unset !important;
  font-size: 14px !important;
  color: white !important;
  font-weight: 400 !important;
}
.loginTabBtn:hover {
  background-color: #7BA6FB !important;
  box-shadow: unset !important;
  border-radius: 8px 8px 0px 0px !important;
}
label {
  font-size: 14px;
  font-weight: 700;
}
.loginHeading > h3 {
  font-size: 24px;
  font-family: Roboto;
  font-weight: 400;
  /* padding-top: 10px; */
}
.forgotPassword {
  font: normal normal normal 16px/20px Source Sans Pro;
  letter-spacing: 0px;
  color: #34518A;
  opacity: 1;
  user-select: none;
}

.loginResendOtp {
  font: normal normal normal 16px/20px Source Sans Pro;
  letter-spacing: 0px;
  color: #34518A;
  opacity: 1;
  user-select: none;
}
.loginBtnContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  /* padding-left: 10vw; */
}

.loginNewLogoContainer {
    
  position: absolute;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: flex-end;

}

.loginMySchoolLogo{
  max-width: 150px !important;
  max-height: 40px;
}

.webCloseModal{
  display:flex;
  align-items: center;
  justify-content: center !important;

}
.crossIconWidth{
  display:flex;
  background-color: white !important;
}

.loginChoice {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
}

   @media screen  and (min-width:320px) and (max-width: 520px) {
  .loginForm{
    padding: inherit;
    padding:10px;
    /* height: 310px; */
  }
  .loginHeading{
    font-size: 24px;
    font-weight: bold;
  }
  .loginParentCard{
    flex:1;
    
  }
  .loginTextFieldContainer {
    padding: 0px 15px;
  }
  .loginContBtn {
    padding: 0px 12px;
  }
  .label {
    padding-bottom: 5px;
  }
   .loginCloseModal{
    position: fixed !important;
    top: 25% !important;
    min-width: 50px !important;
    min-height: 50px !important;
    background-color: white !important;
    border-radius: 30px !important;
    padding: 5px;
    right: 45vw;
  }

  
}
@media screen  and (min-width:720px) and (max-width: 1280px){
  .loginForm {
    padding: 8%;
  }

}
@media screen  and (min-width:720px) and (max-width: 930px){
 
  .loginMySchoolLogo{
    display:none;
  } 
}

